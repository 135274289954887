import React from "react";

const calcdeliverycharge = (distance, deliveryCharges) => {
    if (distance == 0) {
        return { distance: 0, charge: 0, chargeRate: 0, taxProfile: 0 };
    }
    const { radius, delivery_charges } = deliveryCharges;
    const taxrate = Number(deliveryCharges.taxrate);

    if (distance > radius) {
        return { error: "Delivery not available for this distance" };
    }

    let charge = 0;
    let chargeRate = 0;

    for (let charges of delivery_charges) {
        if (distance > charges.startkm && distance <= charges.endkm) {
            if (charges.applyas == "fixed") {
                charge = parseFloat((charges.charge + (charges.charge * taxrate) / 100).toFixed(2));
                chargeRate = charges.charge;
            } else if (charges.applyas == "perkm") {
                charge = parseFloat(
                    (
                        charges.charge * distance +
                        (charges.charge * distance * taxrate) / 100
                    ).toFixed(2)
                );
                chargeRate = charges.charge;
            }
            break;
        }
    }

    return {
        distance,
        charge,
        chargeRate,
        taxrate,
    };
};

export default calcdeliverycharge;
