export const clearDeliveryDetails = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    if (userData) {
        const updatedUserData = { ...userData };
        delete updatedUserData.completeAddress;
        delete updatedUserData.houseFlatNo;
        delete updatedUserData.landmark;
        delete updatedUserData.address;
        delete updatedUserData.city;
        delete updatedUserData.distance;
        delete updatedUserData.deliveryCharge;

        localStorage.setItem("userData", JSON.stringify(updatedUserData));
    }
};
