import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Button,
    Divider,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Snackbar,
    Alert,
    InputLabel,
    MenuItem,
    FormControl,
    Select
} from "@mui/material";
import DiningIcon from '@mui/icons-material/Dining';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import pmo from "../../assets/pmo.gif";
import "./mybasket.css";
// import "../DiscountCard/DiscountCard.css";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../component/Spinner/Spinner";
import OfferModal from "../ModalComp/OfferModal";
import axiosApiFunction from "../../utils/axiosapifunc";
import OfferContext from "../../context/OfferContext";
import ErrorContext from "../../context/ErrorContext";
import { addCart, applyOffer, applyOrderLevelCharge, isDeliveryOrder, recalcOrderLevelCharge, removeItem, removeOffer } from "../../redux/slice/CartSlice";
import moment from "moment";
import ToggleSwitch from "../Toggle/ToggleSwitch";
import DrawerComp from '../Drawer/DrawerComp'
import { useNavigate } from "react-router-dom";
import { pushStoreData } from "../../redux/slice/StoreSlice";
import { img } from "framer-motion/client";
import LoginAtCheckoutModal from "../ModalComp/LoginAtCheckoutModal";
import calcdeliverycharge from "../../utils/calcdeliverycharge";
import { clearDeliveryDetails } from "../../utils/cleardeliverydetails";

const MyBasket = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [offerData, setOfferData] = useState(false);
    // const [isOfferApplied, setIsOfferApplied] = useState(false);
    // const [appliedOfferName, setAppliedOfferName] = useState("");
    // const [appliedOfferDescription, setAppliedOfferDescription] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [openAddress, setOpenAddress] = useState(false);
    const [storeDetail, setStoreDetail] = useState([]);
    const [isDelivery, setIsDelivery] = useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [itemLevelCharge, setItemLevelCharge] = useState({});
    const [orderLevelCharge, setOrderLevelCharge] = useState({});
    const [totalWoutRoundoff, setTotalWoutRoundoff] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
    
    const locationData = JSON.parse(localStorage.getItem('locationData'));

    const { apiError, setApiError, clearApiError } = useContext(ErrorContext);
    const offerCtx = useContext(OfferContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getStore = useSelector((state) => state.store?.storeData);
    const getCart = useSelector((state) => state.cart?.cartData);
    const getTotalTax = useSelector((state) => state.cart?.totalTax);
    const getTotalAmount = useSelector((state) => state.cart?.totalAmount);
    const getTotalDiscount = useSelector((state) => state.cart?.totalDiscount);
    const getTotalAmountWithTax = useSelector((state) => state.cart?.totalAmountWithTax);
    const totalItemLevelCharge = useSelector((state) => state.cart?.totalItemLevelCharge);
    const totalOrderLevelCharge = useSelector((state) => state.cart?.orderLevelCharge);
    let isDeliveryOrderFlag = useSelector((state) => state.cart?.isDeliveryOrder)

    const getTokenData = JSON.parse(localStorage.getItem("tokenData"));
    let getUserData = JSON.parse(localStorage.getItem("userData")) || {};
    const companyId = JSON.parse(localStorage.getItem('companyId'));
    const getTableId = JSON.parse(localStorage.getItem('tblid')) || 0;
    const [showImage, setShowImage] = useState(true); 
    const [timing, setTiming] = React.useState('ASAP');
    const [options, setOptions] = useState(["ASAP"]);

    if(getUserData) {
        getUserData.isDeliveryType = isDelivery ? 1 : 0;
    }
    localStorage.setItem('userData', JSON.stringify(getUserData));
    

    const buttonText =
        getTableId > 0
            ? "Checkout"
            : getUserData?.isDeliveryType === 1
            ? getUserData?.completeAddress
                ? "Checkout!"
                : "Select Delivery Address"
            : "Checkout!";

    let totalToPay = parseFloat(
        (
            Number(getTotalAmountWithTax) +
            (getTableId == 0 ? Number(totalItemLevelCharge) : 0) +
            (isDelivery && getTableId == 0 ? Number(totalOrderLevelCharge) : 0)
        ).toFixed(2)
    );

    let roundoff = Math.round(totalToPay) - totalToPay;

    totalToPay = (totalWoutRoundoff == 0 && getStore?.pgid != 3) ? Math.round(totalToPay): totalToPay;



    let totalGST = 0;

    for (const key in getTotalTax) {
        if (key === "SGST" || key === "CGST") {
            totalGST = totalGST + getTotalTax[key];
        }
    }

    const handleTimeChange = (event) => {
        const selectedTime = event.target.value;

        if (selectedTime === "ASAP") {
            localStorage.setItem("order-time", "ASAP");
            setTiming(selectedTime);
            return;
        }

        const today = new Date();
        const [hour, minute] = selectedTime
            .split(/[: ]/)
            .map((v, i) => (i < 2 ? parseInt(v, 10) : v));
        const isPM = selectedTime.includes("PM");

        // Convert selected time to a Date object
        const selectedDateTime = new Date(today);
        selectedDateTime.setHours(
            isPM && hour !== 12 ? hour + 12 : !isPM && hour === 12 ? 0 : hour
        );
        selectedDateTime.setMinutes(minute);
        selectedDateTime.setSeconds(0);

        // Check if the selected time is earlier than the current time
        if (selectedDateTime <= today) {
            selectedDateTime.setDate(selectedDateTime.getDate() + 1);
        }

        const formattedDate = `${(selectedDateTime.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${selectedDateTime
            .getDate()
            .toString()
            .padStart(2, "0")}/${selectedDateTime.getFullYear()}`;
        const formattedTime = `${(selectedDateTime.getHours() % 12 || 12)
            .toString()
            .padStart(2, "0")}:${selectedDateTime.getMinutes().toString().padStart(2, "0")} ${
            selectedDateTime.getHours() >= 12 ? "PM" : "AM"
        }`;

        const dateTime =
            selectedTime === "ASAP" ? formattedDate : `${formattedDate} ${formattedTime}`;

        setTiming(selectedTime);
        localStorage.setItem("order-time", dateTime);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const handleOpenModal = () => {
        setOpenDialog(true);
    };

    const handleOfferClick = async (offerdata) => {
        setOpenDialog(false);
        if (getCart?.length === 0) {
            setOpen(true);
            setMessage("Please Add Product To Cart");
            setSeverity("info");
            return;
        }

        let itemPayload = [];

        for (let i = 0; i < getCart.length; i++) {
            let modifiersArray = [];
            if (getCart[i].optionsToAdd.length > 1) {
                modifiersArray = getCart[i].optionsToAdd.slice(1).map((option) => {
                    return {
                        modifiername: option.title,
                        modifiercategoryid: option.optGroupRefIds[0][0],
                        modifierid: option.refId,
                        price: option.price,
                        quantity: 1, // You can adjust this if quantity is different
                    };
                });
            }

            for (let j = 0; j < getCart[i].quantity; j++) {
                itemPayload.push({
                    modifiers: modifiersArray,
                    productid: getCart[i].refId.toString(),
                    productcategoryid: getCart[i].categoryRefIds[0],
                    productportionid:
                        getCart[i].optionsToAdd.length > 0 &&
                        Object.keys(getCart[i].optionsToAdd[0]).length > 0
                            ? getCart[i].optionsToAdd[0].refId
                            : "",
                    productname: getCart[i].title,
                    productQty: 1,
                    taxprofileid: 0,
                    producttaxrate: getCart[i].itemTotalTax,
                    portionname:
                        getCart[i].optionsToAdd.length > 0 &&
                        Object.keys(getCart[i].optionsToAdd[0]).length > 0
                            ? getCart[i].optionsToAdd[0].title
                            : getCart[i].portionName,
                    price:
                        getCart[i].optionsToAdd.length > 0 &&
                        Object.keys(getCart[i].optionsToAdd[0]).length > 0
                            ? getCart[i].optionsToAdd[0].price
                            : parseFloat(getCart[i].price.toFixed(2)),
                    //   productPortionId: variation.refId,
                });
            }
        }

        let applyOfferPayload = {
            offerId: offerdata.offerid,
            amount: getTotalAmount,
            // customerId: getTokenData.data?.customerdetails
            //     ? getTokenData.data?.customerdetails.customerid
            //     : 0,
            items: itemPayload,
        };

        try {
            clearApiError();
            setIsLoading(true);

            const response = await axiosApiFunction(
                "post",
                "/api/v1/applyoffer",
                {
                    companyid: companyId,
                    locationid: locationData.locationid,
                },
                "",
                applyOfferPayload
            );

            if (response.data.success === 1) {
                dispatch(removeOffer());
                dispatch(applyOffer(response.data.items));
                setOpen(true);
                setMessage("Offer applied successfully!");
                setSeverity("success");
                offerCtx.handleOfferNameChange(offerdata.offername);
                offerCtx.handleOfferDescriptionChange(offerdata.offerdescription);
                offerCtx.handleOffer(true);

                const offerData = {
                    offername: offerdata.offername,
                    offerdescription: offerdata.offerdescription,
                    isofferapplied: true
                }

                localStorage.setItem('offerData', JSON.stringify(offerData));
            } else {
                dispatch(removeOffer());
                offerCtx.resetOffer();

                const offerData = {
                    offername: "",
                    offerdescription: "",
                    isofferapplied: false
                }

                localStorage.setItem('offerData', JSON.stringify(offerData));

                setOpen(true);
                setMessage(response.data.msg);
                setSeverity("error");
            }
        } catch (error) {
            setApiError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        clearApiError();
        const companyId = JSON.parse(localStorage.getItem('companyId'));
        const offerData = JSON.parse(localStorage.getItem('offerData'));

        getTableId == 0 ? setIsDelivery(true): setIsDelivery(false);

        if (offerData) {
            offerCtx.handleOfferNameChange(offerData.offername);
            offerCtx.handleOfferDescriptionChange(offerData.offerdescription);
            offerCtx.handleOffer(offerData.isofferapplied);
        }

        const getCurrentDaySlots = (timings) => {
            const currentDay = new Date().toLocaleString("en-US", { weekday: "long" }).toLowerCase(); // Get current day in lowercase
            const todayTimings = timings.find((slot) => slot.day === currentDay);
    
            if (todayTimings && todayTimings.slots.length > 0) {
                const start_time = todayTimings.slots[0].start_time;
                const end_time = todayTimings.slots[0].end_time;
    
                return { start_time, end_time };
            }
    
            return { start_time: "00:00:00", end_time: "00:00:00" }; // Default fallback if no slots are available
        };

        const generateTimeSlots = (storeTimes) => {
            const currentTime = new Date();
            const storeStartTime = new Date(currentTime);
            const storeEndTime = new Date(currentTime);

            // Parse start_time and end_time
            const [startHour, startMinute] = storeTimes.start_time.split(":").map(Number);
            const [endHour, endMinute] = storeTimes.end_time.split(":").map(Number);

            // Set the hours and minutes for start and end times
            storeStartTime.setHours(startHour, startMinute, 0, 0);
            storeEndTime.setHours(endHour, endMinute, 0, 0);

            // Adjust `storeEndTime` to the next day if it is earlier than `storeStartTime`
            if (endHour < startHour || (endHour === startHour && endMinute < startMinute)) {
                storeEndTime.setDate(storeEndTime.getDate() + 1);
            }

            const newOptions = ["ASAP"];
            let nextTime = new Date(currentTime);

            // Round to the next 15-minute interval and add a 30-minute buffer
            nextTime.setMinutes(Math.ceil(nextTime.getMinutes() / 15) * 15);
            if (nextTime <= currentTime) {
                nextTime.setMinutes(nextTime.getMinutes() + 15);
            }

            // Generate time slots in two segments
            while (nextTime <= storeEndTime) {
                if (
                    (nextTime >= storeStartTime && nextTime < new Date(currentTime.setHours(24, 0, 0, 0))) || // Until midnight
                    nextTime < storeEndTime // After midnight
                ) {
                    const formattedTime = nextTime.toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                    });
                    newOptions.push(formattedTime);
                }
                nextTime.setMinutes(nextTime.getMinutes() + 15); // Increment by 15 minutes
            }
            setOptions(newOptions);
        }

        async function getStoreDetails() {
            try {
                let storeDetails = await axiosApiFunction(
                    "get",
                    "/api/v1/getstoredetails",
                    {
                        companyid: companyId,
                        locationid: locationData.locationid,
                    },
                );
                dispatch(pushStoreData(storeDetails.data[0]));
                setStoreDetail(storeDetails.data);
                setTotalWoutRoundoff(storeDetails.data[0].totalwoutroundoff);

                const timings = storeDetails.data[0].timings;
                const storeTimes = getCurrentDaySlots(timings);

                // Generate time slots after getting storeTimes
                generateTimeSlots(storeTimes);
            } catch (error) {
                setApiError(error.message);
            }

        }

        async function fetchOfferData() {
            try {
                setIsLoading(true);

                const response = await axiosApiFunction(
                    "get",
                    "/api/v1/getoffers",
                    { companyid: companyId, locationid: locationData.locationid },
                );
                setOfferData(response.data);
            } catch (error) {
                setApiError(error.message);
            } finally {
                setIsLoading(false);
            }
        }

        fetchOfferData();
        getStoreDetails();
    }, []);
   
    useEffect(() => {
        // Set a timer to hide the image after 4 seconds
        const timer = setTimeout(() => {
            setShowImage(false);
        }, 4000);
        // Cleanup the timer on component unmount
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        setIsDelivery(isDeliveryOrderFlag);
        // getCart.forEach((item) => {
            // if(item?.isOrderLevelChargedProduct == true){
                // dispatch(applyOrderLevelCharge(Number(getStore?.charges?.find(charge => charge.applicableon == 2).chargevalue)))
                if(getCart.length > 0){
                    let userData =  JSON.parse(localStorage.getItem('userData'));

                    if (!userData?.distance || !getStore?.deliverycharge_data) {
                        return; // Exit early if either is missing
                    }

                    const deliveryChargePerDistance = calcdeliverycharge(userData?.distance || 0, getStore.deliverycharge_data);
                    
                    dispatch(applyOrderLevelCharge(deliveryChargePerDistance.charge));
                }
            // }
        // })
    }, [getStore, isDeliveryOrderFlag])

    useEffect(() => {
        setItemLevelCharge({chargeName: getStore?.charges?.find(charge => charge.applicableon == 1)?.chargename, chargeAmt: totalItemLevelCharge})
        setOrderLevelCharge({chargeName: getStore?.charges?.find(charge => charge.applicableon == 2)?.chargename, chargeAmt: totalOrderLevelCharge})
    }, [totalItemLevelCharge, totalOrderLevelCharge])

    useEffect(() => {
        const orderTime = localStorage.getItem('order-time');

        if(orderTime) {
            const [datePart, timePart, endPart] = orderTime.split(" ");
            const today = new Date().toLocaleDateString("en-US");

            if (datePart === today && !timePart) {
                setTiming("ASAP");
            } else if (timePart) {
                setTiming(`${timePart} ${endPart}`);
            } else {
                setTiming("ASAP");
            }
        } else {
            setTiming('ASAP');
            localStorage.setItem('order-time', 'ASAP');
        }
    }, []);

    const isStoreOpen = (timings) => {
        const now = new Date();
        const currentDay = now.toLocaleString("en-US", { weekday: "long" }).toLowerCase();
        const currentTime = now.toTimeString().slice(0, 8);

        const getPriviousDay = (dayIndex) => {
            return dayIndex === 0 ? timings.length - 1 : dayIndex - 1;
        };

        const currentDayIndex = timings.findIndex((timing) => timing.day === currentDay);

        if (currentDayIndex === -1) {
            return false;
        }

        for (const timing of timings) {
            if (timing.day === currentDay) {
                for (const slot of timing.slots) {
                    const startTime = slot.start_time.slice(0, 8);
                    const endTime = slot.end_time.slice(0, 8);

                    if (startTime < endTime) {
                        if (currentTime >= startTime && currentTime < endTime) {
                            return true;
                        }
                    } else {
                        const previousDayIndex = getPriviousDay(currentDayIndex);
                        const previousDayTimings = timings[previousDayIndex];

                        for (const slot of previousDayTimings.slots) {
                            const previousDayEndTime = slot.end_time.slice(0, 8);

                            if (currentTime >= startTime) {
                                return true;
                            }

                            if (currentTime < previousDayEndTime) {
                                return true;
                            } else {
                                return false;
                            }
                        }
                    }
                }
            }
        }

        return false;
    };

    const handleDeliveryAddressSelect = () => {
        if (getCart?.length === 0) {
            setOpen(true);
            setMessage("Unable to proceed to checkout. Your cart is currently empty");
            setSeverity("error");
            return;
        }

        setIsDrawerOpen(true);
    }
    const handleCheckoutClick = () => {
        const userToken = JSON.parse(localStorage.getItem('tokenData'))?.token;

        let enableHomeDel = storeDetail[0]?.enable_homedelivery;
        let enablePickupDel = storeDetail[0]?.enable_pickup;

        let minOrderValueHomeDel = storeDetail[0]?.min_order_value_for_homedelivery;
        let minOrderValuePickup = storeDetail[0]?.min_order_value_for_pickup;

        let paylaterEnable = storeDetail[0]?.enablepaylater;
        let onlinePaymentAvailable = false;
        let pgid =  storeDetail[0]?.pgid;

        if(pgid > 0) onlinePaymentAvailable = true;

        if (getCart?.length === 0) {
            setOpen(true);
            setMessage("Unable to proceed to checkout. Your cart is currently empty");
            setSeverity("error");
            return;
        }

        // || !(Object.keys(getStore).length > 0 && isStoreOpen(getStore.timings)
        let storeBoolean = (storeDetail[0]?.active == "true" || storeDetail[0]?.active == true) ? 1 : 0;
        if (!storeBoolean || !(Object.keys(getStore).length > 0 && isStoreOpen(getStore.timings))) {
            setOpen(true);
            setMessage("Unable to proceed to checkout. Currently store is closed");
            setSeverity("error");
            return;
        }

        if(!(onlinePaymentAvailable || (paylaterEnable == 1))) {
            setOpen(true);
            setMessage("Unable to proceed to checkout. Currently no payment option available");
            setSeverity("error");
            return;
        }

        if ((enableHomeDel == false || enableHomeDel == "false") && isDelivery && getTableId == 0) {
            setOpen(true);
            setMessage("Delivery Service is Currently Unavailable at the store");
            setSeverity("warning");
            return;
        }

        if ((enablePickupDel == false || enablePickupDel == "false") && !isDelivery && getTableId == 0) {
            setOpen(true);
            setMessage("Pick-up Service is Currently Unavailable at the store");
            setSeverity("warning");
            return;
        }

        if (!userToken) {
            // setOpenAddress(false);
            setIsLoginModalOpen(true);
            return;
        }

        if (minOrderValueHomeDel && minOrderValueHomeDel > 0 && isDelivery && minOrderValueHomeDel > getTotalAmountWithTax && getTableId == 0) {
            setOpen(true);
            setMessage(`The minimum order value for home delivery is ${Number(minOrderValueHomeDel).toFixed(2)} Please add more items to continue.`);
            setSeverity("warning");
            return;
        }
        

        if (minOrderValuePickup && minOrderValuePickup > 0 && !isDelivery && minOrderValuePickup > getTotalAmountWithTax && getTableId == 0) {
            setOpen(true);
            setMessage(`The minimum order value for pickup is ${Number(minOrderValuePickup).toFixed(2)} Please add more items to continue.`);
            setSeverity("warning");
            return;
        }

        isDelivery && !(getTableId > 0) && !(JSON.parse(localStorage.getItem("userData"))?.completeAddress)
            ? setIsDrawerOpen(true)
            : handleSubmit(isDelivery);
    }


    const handleSubmit = async () => {

        clearApiError();

        // let customerData = await getTokenData.data;

        // let itemsArrayToSave = await getCart.map((item) => {
        //     let itemOptions = item.optionsToAdd.map((option) => ({
        //         // id: option.id,
        //         merchant_id: option.refId.toString(),
        //         price: option.price,
        //         title: option.title,
        //         translations: null,
        //         unit_weight: option.weight,
        //     }));

        //     let modifiers =
        //         item.optionsToAdd.length > 1
        //             ? item.optionsToAdd.slice(1).map((modifier) => ({
        //                   modifercategory: modifier.optGroupRefIds[0][0],
        //                   modifierprice: modifier.price,
        //                   modifierqty: 1,
        //                   modifierid: modifier.refId,
        //                   productmodifierid: "",
        //               }))
        //             : [];

        //     let productDiscount = parseFloat(
        //         (Number(item.discount) / 100) *
        //             (item.optionsToAdd.length > 0 ? item.optionsToAdd[0].price : item.price) *
        //             item.quantity
        //     ).toFixed(3);

        //     let calculateTotalTaxablePrice =
        //         item.optionsToAdd.length > 0
        //             ? parseFloat(
        //                   (
        //                       (item.totalPrice -
        //                           item.optionsToAdd
        //                               .slice(1)
        //                               .reduce((acc, mod) => acc + mod.price, 0)) *
        //                           item.quantity *
        //                           (1 - Number(item.discount) / 100) +
        //                       item.optionsToAdd.slice(1).reduce((acc, mod) => acc + mod.price, 0) *
        //                           item.quantity
        //                   ).toFixed(2)
        //               )
        //             : parseFloat(
        //                   (
        //                       item.totalPrice *
        //                       item.quantity *
        //                       (1 - Number(item.discount) / 100)
        //                   ).toFixed(2)
        //               );

        //     let itemTotal = item.totalPrice * item.quantity;

        //     return {
        //         product_id: item.refId, // *** need to be changed */
        //         productcategoryid: item.categoryRefIds[0],
        //         modifiers: modifiers,
        //         taxprofileid: "",
        //         productportionid: item.optionsToAdd.length > 0 ? item.optionsToAdd[0].refId : "",
        //         productname: item.title,
        //         price: parseFloat(item.price),
        //         quantity: item.quantity.toString(),
        //         taxableprice: calculateTotalTaxablePrice,
        //         totaltaxprice: calculateTotalTaxablePrice + item.itemTotalTax,
        //         total: itemTotal,
        //         charges: "",
        //         discountpercentage: item.discount,
        //         discount: productDiscount,
        //         taxes: item.itemLevelTax.map((tax) => ({
        //             rate: tax.structure.value.toString(),
        //             value: parseFloat((tax.structure.value * item.price) / 100),
        //             taxid: tax.title == "SGST" ? "1" : tax.title == "CGST" ? "2" : "",
        //         })),
        //         portionid: null,
        //         portionname: item.optionsToAdd.length > 0 ? item.optionsToAdd[0].title : null,
        //         offerid: item.offerId.toString(),
        //         promocode: "",
        //         offertype: "",
        //         discountedqty: 0,
        //         discounttype: 1,
        //         discounonproduct: productDiscount,
        //         totalwithoutdiscountortax: itemTotal,
        //         pricewithouttaxandmodifier: itemTotal,
        //         discountapplied: item.discount > 0 ? 1 : 0,
        //     };
        // });

        let itemsArray = await getCart.map((item) => {
            let itemOptions = item.optionsToAdd
            .filter(option => Object.keys(option).length > 0) // Exclude empty objects
            .map(option => ({
                merchant_id: option.refId.toString(),
                price: option.price,
                title: option.title,
                translations: null,
                unit_weight: option.weight,
            }));

            return {
                charges: [],
                discount: parseFloat(item.discount),
                food_type: item.foodType.toString(),
                image_landscape_url: null,
                image_url: item.imgUrl,
                merchant_id: item.refId.toString(),
                options_to_add: itemOptions,
                options_to_remove: [], // ***
                price: parseFloat(item.price),
                quantity: item.quantity,
                taxes: item.itemLevelTax.map((tax) => ({
                    rate: tax.structure.value,
                    title: tax.title,
                    value: parseFloat((item.itemTotalTax / 2).toFixed(3)),
                })),
                title: item.title,
                offerid: item.offerId,
                total: (item.totalPrice * item.quantity).toString(),
                total_with_tax: parseFloat(
                    parseFloat(item.totalPrice) + parseFloat(item.itemTotalTax)
                ),
                translations: null,
                unit_weight: item.weight,
            };
        });

        let productData = {
            companyid: companyId,
            locationid: locationData.locationid,
            items: itemsArray,
        };

        // return;

        try {
            setIsLoading(true);
            const userToken = JSON.parse(localStorage.getItem('tokenData'))?.token;

            const response = await axiosApiFunction(
                "post",
                "/api/v1/validateorder",
                {},
                userToken,
                productData
            );

            let resultResp = response.data;
            // return;
            if (
                resultResp.success === 1 &&
                resultResp.invalid_portions === 0 &&
                resultResp.invalid_products === 0
            ) {
                navigate('/payment');
            } else {
                if (resultResp.invalid_products > 0 || resultResp.invalid_portions > 0) {
                    const invalidProducts = resultResp.data?.invalid_products || {};
                    const invalidPortions = resultResp.data?.invalid_portions || {};

                    // const filteredCart = getCart.filter((item) => {
                    //     const isValidProduct = !(item.refId in invalidProducts);

                    //     const areValidOptions = item.optionsToAdd.every((option) => {
                    //         return !(option.refId in invalidPortions);
                    //     });

                    //     return isValidProduct && areValidOptions;
                    // });

                    const cartWithInvalidFlags = getCart.map((item) => {
                        const isInvalidProduct = item.refId in invalidProducts;
                        const hasInvalidOptions = item.optionsToAdd.some((option) => {
                            return option.refId in invalidPortions;
                        });

                        return {
                            ...item,
                            isInvalidProduct,  // Mark product as invalid
                            hasInvalidOptions, // Mark if product has invalid options
                        };
                    });

                    dispatch(addCart(cartWithInvalidFlags));

                    setOpen(true);
                    setMessage("Some product(s) from the cart are invalid. Please remove them to proceed to checkout");
                    setSeverity("error");
                }
            }
        } catch (error) {
            setOpen(true);
            setMessage(error.message);
            setSeverity("error");
        } finally {
            setIsLoading(false);
        }
    };

    const handleRemoveOffer = () => {
        offerCtx.resetOffer();

        const offerData = {
            offername: "",
            offerdescription: "",
            isofferapplied: false
        }
        localStorage.setItem('offerData', JSON.stringify(offerData));
        dispatch(removeOffer());
    }

    const handleSwitch = (flag) => {
        dispatch(isDeliveryOrder(flag));
        setIsDelivery(flag);
    }

    return (
        <div>
            <Spinner enabled={isLoading} />
            <div className="card">
                {showImage ? (
                    // Show only the image for 4 seconds
                    <img className="setpmoimg" src={pmo} alt="Offer" />
                ) : (
                    <>
                        <div className="code">
                            <div className="icon">%</div>
                            <div className="code-text">
                                {offerCtx.isOfferApplied
                                    ? offerCtx.appliedOfferName
                                    : offerData.length > 0
                                    ? offerData[0].offername
                                    : "No Offer Available"}
                            </div>
                        </div>
                        <div className="description">
                            {offerCtx.isOfferApplied ? (
                                offerCtx.appliedOfferDescription.length > 80 ? (
                                    <>
                                        {offerCtx.appliedOfferDescription.slice(0, 80)}
                                        {!isExpanded
                                            ? "..."
                                            : offerCtx.appliedOfferDescription.slice(80)}
                                        <span
                                            onClick={() => setIsExpanded(!isExpanded)}
                                            className="read-more-offer"
                                        >
                                            {isExpanded ? " Read Less" : " Read More"}
                                        </span>
                                    </>
                                ) : (
                                    offerCtx.appliedOfferDescription
                                )
                            ) : offerData.length > 0 ? (
                                offerData[0].offerdescription.length > 80 ? (
                                    <>
                                        {offerData[0].offerdescription.slice(0, 80)}
                                        {!isExpanded
                                            ? "..."
                                            : offerData[0].offerdescription.slice(80)}
                                        <span
                                            onClick={() => setIsExpanded(!isExpanded)}
                                            className="read-more-offer"
                                        >
                                            {isExpanded ? " Read Less" : " Read More"}
                                        </span>
                                    </>
                                ) : (
                                    offerData[0].offerdescription
                                )
                            ) : (
                                "No offers available at the moment."
                            )}
                        </div>
                        <div className="actions">
                            {offerCtx.isOfferApplied ? (
                                <a className="apply-link" onClick={() => handleRemoveOffer()}>
                                    Remove Offer
                                </a>
                            ) : (
                                offerData.length > 0 && (
                                    <a
                                        className="apply-link"
                                        onClick={() => handleOfferClick(offerData[0])}
                                    >
                                        Apply
                                    </a>
                                )
                            )}
                            {offerData.length > 0 && (
                                <a className="more-offers" onClick={handleOpenModal}>
                                    View more offers
                                </a>
                            )}
                        </div>
                    </>
                )}

                {/* <img className="setpmoimg" src={pmo} /> */}
            </div>
            {getTableId == 0 && (
                <div className="card">
                    <Box className="basket-header">
                        <DiningIcon className="order-header-icon" />
                        <Typography variant="h6" className="basket-title">
                            Ordering Type
                        </Typography>
                    </Box>
                    <Divider />
                    <Box className="swichsetup">
                        <ToggleSwitch handleSwitch={handleSwitch} isDeliveryFlag={isDelivery} />
                    </Box>
                    <div className="order-row">
                        <div className="order-label">
                            <AccessTimeFilledRoundedIcon className="order-icon" />
                            <span className="order-text">Select time:</span>
                        </div>
                        <FormControl variant="filled" className="order-select">
                            <InputLabel id="timing-selection">Timings</InputLabel>
                            <Select
                                labelId="timing-selection"
                                // id="demo-simple-select-filled"
                                value={timing}
                                onChange={handleTimeChange}
                            >
                                {options.map((time, index) => (
                                    <MenuItem key={index} value={time}>
                                        {time}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {isDelivery && (
                        <div className="order-address-row">
                            <div className="order-address-label">
                                <LocationOnRoundedIcon className="order-address-icon" />
                                <span className="order-address-text">
                                    {JSON.parse(localStorage.getItem("userData"))
                                        ?.completeAddress || "No Delivery Address Selected"}
                                </span>
                            </div>
                            <a
                                className="order-address-select"
                                onClick={handleDeliveryAddressSelect}
                                // onClick={() => setIsDrawerOpen(true)}
                            >
                                {JSON.parse(localStorage.getItem("userData"))?.completeAddress
                                    ? "Change"
                                    : "Select"}
                            </a>
                        </div>
                    )}
                </div>
            )}
            <Box className="basket">
                <Box className="basket-header">
                    <ShoppingBasketIcon className="basket-icon" />
                    <Typography variant="h6" className="basket-title">
                        My Basket
                    </Typography>
                </Box>
                <Divider />
                <Snackbar
                    open={open}
                    autoHideDuration={5000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={severity}
                        sx={{ width: "100%" }}
                        style={{ marginTop: "50px", textAlign: "start" }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
                <Box className="basket-summary">
                    <Box className="basket-summary-item">
                        <Typography>Sub Total:</Typography>
                        <Typography>
                            {getStore?.currencysymbol}{" "}
                            {parseFloat((getTotalAmount + getTotalDiscount).toFixed(2))}
                        </Typography>
                    </Box>
                    <Box className="basket-summary-item">
                        <Typography>Discounts:</Typography>
                        <Typography>
                            {getStore?.currencysymbol} {getTotalDiscount}
                        </Typography>
                    </Box>
                    <Box className="basket-summary-item">
                        <Typography>GST:</Typography>
                        <Typography>
                            {getStore?.currencysymbol} {totalGST}
                        </Typography>
                    </Box>
                    {totalWoutRoundoff == 0 && getStore?.pgid != 3 && (
                        <Box className="basket-summary-item">
                            <Typography>Round Off:</Typography>
                            <Typography>{roundoff.toFixed(2)}</Typography>
                        </Box>
                    )}
                    {getTableId == 0 && itemLevelCharge?.chargeAmt > 0 && (
                        <Box className="basket-summary-item">
                            <Typography>Packaging Charge:</Typography>
                            <Typography>
                                {getStore?.currencysymbol} {itemLevelCharge?.chargeAmt}
                            </Typography>
                        </Box>
                    )}
                    {getTableId == 0 && isDelivery && orderLevelCharge?.chargeAmt > 0 && (
                        <Box className="basket-summary-item">
                            <Typography>Delivery Charge:</Typography>
                            <Typography>
                                {getStore?.currencysymbol} {orderLevelCharge?.chargeAmt}
                            </Typography>
                        </Box>
                    )}
                </Box>
                {/* <Button
                    variant="outlined"
                    className="basket-offer-button"
                    onClick={handleOpenModal}
                >
                    Available Offers{" "}
                    <span className="basket-offer-icon">
                        <ArrowCircleRightIcon sx={{ fontSize: 30 }} />
                    </span>
                </Button> */}
                <p variant="contained" className="basket-total-button">
                    <span className="total-to-pay">Total To Pay: </span> {getStore?.currencysymbol}
                    {totalToPay}
                </p>

                {/* {getTotalDiscount > 0 && (
                    <Button
                        variant="contained"
                        className="basket-total-button"
                        onClick={() => dispatch(removeOffer())}
                    >
                        Remove Offer
                    </Button>
                )} */}
                {/* {getTableId == 0 && (
                    <Box className="swichsetup">
                        <ToggleSwitch handleSwitch={handleSwitch} isDeliveryFlag={isDelivery} />
                    </Box>
                )}
                {!isDelivery && getTableId == 0 && (
                    <div className="order-row">
                        <div className="order-label">
                            <AccessTimeFilledRoundedIcon className="order-icon" />
                            <span className="order-text">Pickup time:</span>
                        </div>
                        <FormControl variant="filled" className="order-select">
                            <InputLabel id="timing-selection">Timings</InputLabel>
                            <Select
                                labelId="timing-selection"
                                // id="demo-simple-select-filled"
                                value={timing}
                                onChange={handleTimeChange}
                            >
                                {options.map((time, index) => (
                                    <MenuItem key={index} value={time}>
                                        {time}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                )} */}
                <Button
                    variant="contained"
                    className="basket-checkout-button"
                    onClick={handleCheckoutClick}
                >
                    <span className="basket-offer-icons">
                        {JSON.parse(localStorage.getItem("userData"))?.completeAddress ||
                        getTableId > 0 ||
                        !(getUserData?.isDeliveryType === 1) ? (
                            <ArrowCircleRightIcon
                                sx={{ fontSize: 30 }}
                                className="checkout-icon-cart"
                            />
                        ) : (
                            <LocationOnRoundedIcon
                                sx={{ fontSize: 30 }}
                                className="checkout-icon-cart"
                            />
                        )}
                    </span>{" "}
                    {buttonText}
                </Button>
                {/* <Box className="basket-delivery">
                    <RadioGroup row className="basket-delivery-options" defaultValue="pickup">
                        <FormControlLabel
                            value="delivery"
                            control={<Radio />}
                            label="Delivery"
                            onClick={() => setOpenAddress(true)}
                        />
                        <FormControlLabel
                            value="pickup"
                            control={<Radio />}
                            label="Pick up"
                            onClick={() => setOpenAddress(false)}
                        />
                    </RadioGroup>
                    {openAddress && (
                        <TextField
                            label="Enter Your Delivery Address"
                            variant="outlined"
                            fullWidth
                            className="basket-delivery-address"
                        />
                    )}
                    <Button
                        variant="contained"
                        className="basket-submit-button"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Box> */}

                {/* <DialogComp openDialog={openDialog} setOpenDialog={setOpenDialog} dialogItem={dialogItem} /> */}
                <OfferModal
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    offerData={offerData}
                    handleOfferClick={handleOfferClick}
                />
            </Box>

            <DrawerComp
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                handleSubmit={handleSubmit}
            />
            <LoginAtCheckoutModal
                openDialog={isLoginModalOpen}
                setOpenDialog={setIsLoginModalOpen}
                companyId={companyId}
                locationId={locationData?.locationid}
                locationData={locationData}
            />
        </div>
    );
};

export default MyBasket;
